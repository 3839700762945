<template>
  <div class="">
    
    <!-- UserStore BigCommerceProductModal -->
    <ModalVue
      ref="BigCommerceProductModal"
      id="BCProductStore"
      title="<span class=''>Variants as products</span>"
      size="lg">
      <div class="px-3">
        <FilterBox
                :search="true"
                placeholder="Search Products..."
                @input="searchProductQueryHandle($event)"></FilterBox>
        <div class="row">
         
         
          <div class="col-12 mt-2">
            <div class="d-flex flex-wrap" style="gap: 6px">
               <FilterSelect
              ref="type"
                style="z-index: 12"
                filterFor="Type"
                @update="
                  (e) => {
                    filter.type = e.value;
                  }
                "
                :isDynamic="false"
                type="radio"
                :static-options="[
                  { title: 'Physical', value: 'physical' },
                  { title: 'Digital', value: 'digital' },
                ]"></FilterSelect> 
              <FilterDropdown
                ref="price"
                style="z-index: 12"
                filterFor="Price range"
                @update="
                  (e) => {
                    filter.max_price =e.length?e[1].value:'';
                    filter.min_price = e.length?e[0].value:'';
                  }
                "
                :input="[
                  { type: 'number', value: '', label: 'Min' },
                  { type: 'number', value: '', label: 'Max' },
                ]"></FilterDropdown>
             <!-- <FilterSelect
                ref="visible"
                filterFor="Visibility"
              @update="(e) => {filter.is_visible = e!=null?e.value:''}
                "
                :is-cross-btn="false"
                :selectedObject="{ title: 'Visible', value: 1 }"
                label="title"
                :isDynamic="false"
                type="radio"
                :static-options="[{title: 'All', value: ''},{title: 'Visible', value: 1},{title: 'Invisible', value: 0}]"></FilterSelect> -->
                 <FilterSelect
                ref="category"
                filterFor="Category"
                @update="
                  (e) => {
                    filter.categories = e.map((cat) => cat.id);
                  }
                "
                label="name"
                :isDynamic="false"
                type="checkbox"
                :static-options="categories"></FilterSelect>
             <FilterSelect
              ref="brand"
                filterFor="Brands"
                @update="
                  (e) => {
                    filter.brands = e.map((brand) => brand.id);
                  }
                "
                label="name"
                :isDynamic="false"
                type="checkbox"
                :static-options="brands"></FilterSelect> 
             
                 <b-button variant="link"  style="font-size: 14px; color: #4d1b7e;"  v-if="isHideSelectAllButton"  @click="clearAll()">Clear All</b-button>
            </div>
          </div>
        </div>
        <div class="loader px-2">
         
            <div
              class="d-flex align-items-center"
              v-if="isLoadingStoreProducts">
              <b-spinner small style="color: #4d1b7e"></b-spinner>
              <span class="ml-2">Loading Products</span>
            </div>
          
        </div>
        <b-skeleton-table
          v-if="isLoadingProductFirstTime"
          :rows="5"
          :columns="3"
          :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
        <div class="data-table" v-else-if="userStoreProducts.length">
          <div
            class=""
            style="max-height: 40vh; min-height: 40vh; overflow: auto">
            
            <b-table
              primary-key="id"
              @row-clicked="onRowClicked"
              head-class="text-center"
              class="leadTable"
              :tdClass="'cursor-pointer'"
              :stickyColumn="true"
              hover
              :fields="ProductsFields"
              :items="userStoreProducts">
              <template #head(checkbox)>
                <b-form-checkbox
                  size="lg"
                  :disabled="
                    isLoadingStoreProducts || isLoadingSelectedStoreProducts
                  "
                  v-model="storeSelectAllCheck"
                  @change="shopifyProductSelectAllToggle($event)"
                  class="checkbox selectAll"></b-form-checkbox>
              </template>
              <template #cell(checkbox)="data">
                <b-form-checkbox
                  :value="data.item"
                  v-model="userStoreSelectedProducts"
                  :disabled="
                    isLoadingStoreProducts || isLoadingSelectedStoreProducts
                  "
                  size="lg"
                  class="checkbox"></b-form-checkbox>
              </template>
              <template #cell(image)="data">
              <div class="d-flex gap align-items-center">
                <lazy-image
                     class="circle-image mr-2"
                     :src="
                        data.item.image
                         ? data.item.image
                         : 'https://images.quizell.com/website/default_start_page.png'
                     "
                     :key="data.item.image"></lazy-image>
                <div class="">{{ data.item.title }}</div>
              </div>
            
            </template>
            </b-table>
          </div>
          <div class="py-2 d-flex justify-content-between align-items-center">
            
              <QuizellPagination class="" :total-rows="totalRows" :currentPage="currentPage" :isShowPerPageSelect="false"
                  :per-page="perPage"   v-if="userStoreProducts.length" @update="updatePagination" @UpdatePerPage="updatePerPage"></QuizellPagination>
              <div
              class="">
             
              <Button
                class=""
                @click="syncSelected('')"
                :disabled="
                  isLoadingSync ||
                  isLoadingStoreProducts ||
                  isLoadingSelectedStoreProducts
                "
                :isLoading="isLoadingSelectedStoreProducts"
                v-if="userStoreSelectedProducts.length"
                >Sync Selected
                <b-badge variant="light">{{
                  userStoreSelectedProducts.length
                }}</b-badge></Button
              >
              <Button
                class=""
                @click="syncSelected('all')"
                :disabled="
                  isLoadingSync ||
                  isLoadingStoreProducts ||
                  isLoadingSelectedStoreProducts
                "
                btn-variant="yellow"
                :isLoading="isLoadingSelectedStoreProducts"
                v-else
                >Sync All</Button
              >
            </div>
          </div>
        </div>
        <template v-else>

<div class="d-flex justify-content-center align-items-center"  >

<EmptyTable >
<template #title>
No product found
</template>
<template #description>
Time to fill your shelves or refine your search. Remember, your results will appear right here.
</template>
</EmptyTable>
</div>

</template>
      </div>
    </ModalVue>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { debounce } from "lodash";
import FilterDropdown from "../../Layout/FilterDropdown.vue";
import FilterBox from "../../Layout/FilterBox.vue";
import FilterSelect from "../../Layout/FilterSelect.vue";
import ModalVue from "../../Layout/Modal.vue";
import QuizellPagination from '../../Layout/QuizellPagination.vue';
import axios from "axios";
import Button from "../../Layout/Button.vue";
import EmptyTable from "../../Layout/EmptyTable.vue";
export default {
  components: {
    FilterDropdown,
    FilterBox,
    FilterSelect,
    ModalVue,
    Button,
    EmptyTable,
    QuizellPagination
  },
  props: {
    items: Array,
  },
  data() {
    return {
      withVariant:false,
      overwrite: false,
      selectAllFromStore: false,
      selectAllQuizellProducts: false,
      currentPage: 1,
      totalRows: 0,
      perPage: 0,
      searchProductText: "",
      categories: [],
      brands: [],
      isLoadingStoreProducts: false,
      isLoadingProductFirstTime: false,
      storeSelectAllCheck: false,
      userStoreSelectedProducts: [],
      filter: {
        type: "",
        max_price: "",
        min_price: "",
        brands: [],
        categories: [],
        bc_product_filter: "new",
        is_visible:1,
      },
      userStoreProducts: [],
      ProductsFields: [
        {
          key: "checkbox",
          label: "",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "image",
          label: "Product",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading",
        },
  
         {
          key: "type",
          label: "Type",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading",
        },
          {
          key: "price",
          label: "Price",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading",
        },
      ],

      isLoadingSelectedStoreProducts: false,
      isLoadingDeleteProduct: false,
      isLoadingSync: false,
      isLoading: false,
    };
  },
  watch: {
    filter: {
      handler() {
        this.currentPage = 1;
        this.handleSearchDebounced();
      },
      deep: true,
    },
    allProducts: {
      handler({ selected, all }) {
        this.storeSelectAllCheck =
          all.every((product) => selected.includes(product)) &&
          selected.length > 0;
      },
      deep: true,
    },
  },
  computed: {
     isHideSelectAllButton(){
     const check =  this.filter.brands.length>0||this.filter.type||(this.filter.min_price&&this.filter.max_price)||this.filter.categories.length>0
  
    return check
    },
    payload() {
      const { type, max_price, min_price, brands, categories,bc_product_filter,is_visible } = this.filter;
      let payload = {
            type: type ? type : "",
        max_price: max_price ? max_price : "",
        min_price: min_price ? min_price : "",
        brands: brands ? brands.toString() : "",
        categories: categories ? categories.toString() : "",
        bc_product_filter:this.selectAllFromStore?bc_product_filter:'all',
      }

      if(is_visible!='')
      payload.is_visible=is_visible;
      return payload
    },
    ...mapGetters(["getNavbarData"]),
    allProducts() {
      return {
        selected: this.userStoreSelectedProducts,
        all: this.userStoreProducts,
      };
    },
    selectedProductsIDs() {
      return this.userStoreSelectedProducts;
    },
    shopifyAllProducts() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.userStoreProducts;
    },
  },
  methods: {
    updatePerPage(e){
      this.currentPage=1;
      this.perPage=e;
      this.getProductsFromUserStore();
    },
    updatePagination(e){
      this.currentPage=e;
      this.getProductsFromUserStore();
    },
    clearAll(){
     const ref= this.$refs;
     ref.price.clear();
      ref.type.clear()
       ref.brand.clear()
        ref.category.clear()
         
    },
  
    onRowClicked(item) {
      const index = this.userStoreSelectedProducts.indexOf(item);
      if (index !== -1) this.userStoreSelectedProducts.splice(index, 1);
      else this.userStoreSelectedProducts.push(item);
    },
    async open() {
      this.isLoadingProductFirstTime = true;
      this.userStoreSelectedProducts = [];
      this.searchProductText = "";
      this.currentPage = 1;
      (this.storeSelectAllCheck = false), this.$refs.BigCommerceProductModal.open();
      this.userStoreSelectedProducts = [];
      this.getProductsFromUserStore();
      this.categories = await this.getFilterContent(
        "/api/bigcommerce/categories",{params:{per_page:10000}}
      );
      this.categories =this.categories.collections.data;
      this.brands = await this.getFilterContent("/api/bigcommerce/brands",{params:{per_page:10000}});
      this.brands = this.brands.brands.data;
    },
    async syncSelected(type) {
      this.isLoadingSelectedStoreProducts = true;
      const payload=this.payload
      if(type!='all')
      {
        payload.selected_items=this.selectedProductsIDs
      }
      else if(payload.selected_items){
        delete payload.selected_items
      }
     

      await axios
        .post("/api/bigcommerce/variants-as-products/import", payload)
        .then(() => {
          this.$refs.BigCommerceProductModal.close();
          this.$emit("callDBProducts");
          this.$toasted.show("Products sync successfully", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        })
        .catch((e) => {
          let error = "Error occurred while syncing products"
          if(e.response.status==400)
          error = e.response.data.message
          this.$toasted.show(error, {
            theme: "toasted-primary",
             type: "error",
            position: "bottom-center",
            duration: 2000,
          });
          console.log(e);
        });
      this.isLoadingSelectedStoreProducts = false;
    },
    searchProductQueryHandle(e) {
      this.searchProductText = e;
      this.handleSearchDebounced();
    },
    shopifyProductSelectAllToggle() {
      this.userStoreSelectedProducts = [];
      if (this.storeSelectAllCheck) {
        this.userStoreSelectedProducts = [
          ...new Set([
            ...this.userStoreSelectedProducts,
            ...this.shopifyAllProducts,
          ]),
        ];
      }
    },

    async getProductsFromUserStore() {
      this.isLoadingStoreProducts = true;
      const data = {
        keyword: this.searchProductText,
        ...this.payload,
        page: this.currentPage,
      };

      await axios
        .post("/api/bigcommerce/variants-as-products/load", data)
        .then((response) => {
          const { per_page, total } = response.data.data.meta.pagination;
          this.perPage = per_page;
          this.totalRows = total;
          this.userStoreProducts = JSON.parse(
            JSON.stringify(response.data.data.variants)
          );
        })
        .catch((e) => {
          console.log(e);
        });
      this.isLoadingStoreProducts = false;
      this.isLoadingProductFirstTime = false;
    },
    async getFilterContent(url,payload) {
      return await axios
        .get(url,payload)
        .then((response) => response.data.data)
        .catch((e) => {
          console.log(e);
        });
    },
    handleSearchDebounced: debounce(function () {
      this.getProductsFromUserStore();
    }, 500), // 500ms debounce time, adjust as needed
  },
  mounted() {},
};
</script>
<style scoped>
.userStore-btn:active {
  background-color: #2e2f2f;
  color: #ffffff;
  border-color: #2e2f2f;
}
.userStore-btn {
  appearance: none;
  cursor: pointer;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #030303;
  color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;

  font-size: 14px;

  transition: 150ms ease;
  transition-property: background-color, border-color;
  display: inline-block;

  border-radius: 6px;
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--secondary-color);
  background-color: var(--secondary-color) !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem var(--secondary-color);
}
.leadTable >>> thead {
  border-radius: 6px;
  color: black;
  background: rgba(179, 175, 182, 0.07);
  border: none;
}
.leadTable >>> thead tr {
  border-radius: 10px;
}
.leadTable >>> thead th {
  border: none;
  vertical-align: middle !important;
}
.loader {
  min-height: 12px;
}
.product-image {
  height: 40px;
  width: 40px;
  /* border: 4px solid rgb(255, 255, 255); */
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  background-size: cover;
  /* padding: 0.25rem; */
  /* background-color: #fff; */
}
.product-stripe {
  position: relative;
  top: 38px;
  background: #f4f3f4;
  display: flex;
  align-items: center;
  top: 48px;
}
.product-stripe strong {
  color: #4d1b7e;
  cursor: pointer;
}
.product-stripe strong:hover {
  text-decoration: underline;
}
.customPagination >>> li > button {
  color: #4d1b7e;
}
.cursor-pointer {
  cursor: pointer !important;
}
.customPagination >>> li.active > button {
  color: white;
  background-color: #4d1b7e !important;
  border-color: #4d1b7e;
}
.customPagination >>> li > button:hover {
  color: white;
  background-color: #6b4690 !important;
  border-color: #4d1b7e;
}
#product-detail >>> button {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
}
#product-detail >>> button::after {
  display: none;
}
#product-detail >>> ul {
  background: #ffffff;
  box-shadow: 0px 10px 20px -3px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
#product-detail >>> li.topbarLink {
  color: #344054;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}
#product-detail >>> li a:active {
  color: #fff;
  text-decoration: none;
  background-color: #4d1b7e;
}
</style>

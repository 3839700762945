<template>
  <div style="font-size: 14px; z-index: 11">
    <div
      class="custom-filter-select"
      @click="toggleDropdown"
      ref="customSelect">
      <div class="d-flex justify-content-between align-items-center">
        <span
          class="text-capitaliz"
          style="
            text-wrap: nowrap;
            text-transform: capitalize;
            overflow: hidden;
          "
          >{{
            formatSelectedOptions ? formatSelectedOptions : `${filterFor} `
          }}</span
        >
        <span class=" " @click="ClearAll" v-if="formatSelectedOptions">
          <b-icon class="cross-btn" icon="x" />
        </span>
        <span v-else>
          <b-icon
            class=""
            :icon="isDropdownOpen ? 'chevron-up' : 'chevron-down'" />
        </span>
      </div>
      <transition name="slide">
        <div v-show="isDropdownOpen" class="options" @click.stop="">
          <div class="p-2" v-if="getOptions.length > 0">
            <div
              class="py-1"
              v-for="(option, index) in getOptions"
              :key="option.label + index">
              <!-- <label
            :for="option.label + index"
              class="m-0 p-0"
             >
             {{option.label}}
            </label> -->
              <input
                :type="option.type"
                :id="option.label + index"
                v-model="option.value"
                :placeholder="option.label"
                style=""
                class="price-control py-1" />
            </div>
            <div class="d-flex justify-content-between">
              <b-button
                variant="link"
                class="px-0"
                style="font-size: 14px; color: #4d1b7e"
                @click="ClearAll()"
                >Clear All</b-button
              >
              <b-button
                variant="link"
                class="px-0"
                style="font-size: 14px; color: #4d1b7e"
                @click="updateValues"
                >Apply</b-button
              >
            </div>
          </div>
          <div class="px-2 py-4" style="text-transform: capitalize" v-else>
            {{ filterFor }} not available.
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    // type: {
    //   type: String,
    //   default: "checkbox",
    // },

    filterFor: {
      type: String,
      default: "quizzes",
    },

    input: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    selectedArray(newValue) {
      if (newValue.length > 0)
        this.selectedOptions = newValue ? this.selectedArray : [];
    },
    selectedOptions: {
      handler(newValue) {
        this.$emit("update", newValue);
      },
      deep: true,
    },
  },
  computed: {
    formatSelectedOptions() {
      let str = "";
      this.appliedOptions.forEach((option) => {
        if (option.value) {
          str += option.label + ":" + option.value + " ";
        }
      });
      return str;
    },
  },
  data() {
    return {
      appliedOptions: [],
      getOptions: [...this.input],
      isFirstTime: false,
      range: {
        startDate: null,
        endDate: null,
      },
      today: new Date(),
      isDropdownOpen: false,
      options: [],
      selectedOptions: [],
      transitionClass: "slide", // Default transition class
      loadingFilter: false,
    };
  },

  methods: {
    updateValues() {
      this.appliedOptions = this.getOptions;
      this.$emit("update", this.getOptions);
      this.toggleDropdown();
    },

    ClearAll() {
      this.getOptions.forEach((op) => {
        op.value = "";
      });
      this.updateValues();
    },

    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;

      if (this.isDropdownOpen) {
        this.$nextTick(() => {
          document.addEventListener("click", this.closeDropdownOnClickOutside);
        });
      } else {
        document.removeEventListener("click", this.closeDropdownOnClickOutside);
      }
    },
    closeDropdownOnClickOutside(event) {
      // Check if the clicked element is outside the custom select dropdown
      if (!this.$refs.customSelect.contains(event.target)) {
        this.isDropdownOpen = false;
        document.removeEventListener("click", this.closeDropdownOnClickOutside);
      }
    },
    clearSelection() {
      this.selectedOptions = [];
      this.isDropdownOpen = false; // Close the dropdown when clearing selection
    },
    clear() {
      this.ClearAll()
      // this.selectedOptions = [];
    },
  },
};
</script>

<style scoped>
.price-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;

  border-radius: 0.25rem;
  height: 25px;
  border: none;
  border-bottom: 1px solid #ddd8d8;
}
.price-control:focus-visible {
  box-shadow: none !important;
  outline:none;
  border-bottom: 1px solid #4d1b7e;
}
.price-control:active {
  box-shadow: none !important;
  border-bottom: 1px solid #4d1b7e;
}

.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--secondary-color);
  background-color: var(--secondary-color) !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem var(--secondary-color);
}
.options {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #e3e3e3;
  max-height: 200px;
  overflow-y: auto;
  z-index: 2;
  border-radius: 12px;
  width: 200px;
}

label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  padding: 11px 12px;
}

.slide-down-enter-active,
.slide-up-leave-active,
.slide-down-enter-active,
.slide-up-leave-active {
  transition: all 0.3s;
}

.slide-down-enter,
.slide-up-leave-to,
.slide-up-enter,
.slide-down-leave-to {
  opacity: 0;
  transform: translateY(-20%);
}
.cross-btn:hover {
  color: #4d1b7e;
}
.custom-filter-select >>> .vue-daterange-picker >>> .btn {
  padding: 0;
  font-size: 12px;
}
.custom-filter-select {
  position: relative;
  cursor: pointer;

    padding: 10px 12px;
   
    border-radius: 6px;
    border: 1px solid var(--border-color);
    background: #F6F6F8;
    color: var(--grey-button);
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  /* padding: 3px 10px; */
  max-width: 250px;
  min-width: 150px;
}
</style>

<template>
 
    <div class="d-flex align-items-center w-100 search px-2">
    
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.0917 16.9083L15 13.8416C16.2001 12.3453 16.7813 10.4461 16.6241 8.5344C16.4668 6.62273 15.5831 4.84397 14.1546 3.56385C12.7262 2.28374 10.8615 1.59957 8.94414 1.65204C7.02674 1.70451 5.20231 2.48962 3.84599 3.84593C2.48968 5.20225 1.70457 7.02668 1.6521 8.94408C1.59963 10.8615 2.2838 12.7261 3.56391 14.1546C4.84403 15.583 6.62279 16.4667 8.53446 16.624C10.4461 16.7812 12.3454 16.2001 13.8417 15L16.9084 18.0666C16.9858 18.1448 17.078 18.2068 17.1796 18.2491C17.2811 18.2914 17.39 18.3131 17.5 18.3131C17.6101 18.3131 17.719 18.2914 17.8205 18.2491C17.9221 18.2068 18.0142 18.1448 18.0917 18.0666C18.2419 17.9113 18.3259 17.7036 18.3259 17.4875C18.3259 17.2714 18.2419 17.0637 18.0917 16.9083ZM9.16671 15C8.01298 15 6.88517 14.6579 5.92588 14.0169C4.9666 13.3759 4.21892 12.4649 3.77741 11.399C3.3359 10.3331 3.22038 9.16018 3.44546 8.02862C3.67054 6.89707 4.22611 5.85767 5.04192 5.04186C5.85773 4.22605 6.89713 3.67048 8.02868 3.4454C9.16024 3.22032 10.3331 3.33584 11.399 3.77735C12.4649 4.21886 13.376 4.96654 14.0169 5.92582C14.6579 6.88511 15 8.01292 15 9.16665C15 10.7137 14.3855 12.1975 13.2915 13.2914C12.1975 14.3854 10.7138 15 9.16671 15Z" fill="currentColor"/>
</svg>

      <input type="text" v-model="searchTxt" :placeholder="placeholder" v-bind="$attrs"  id=""  class="w-100 search-text" style="border: none;min-height: 30px;flex:1;">
    </div>
      
    
</template>
<script>

export default {
    props:{
       
        filterBtn:Boolean,
        search:Boolean,
        placeholder:String,
        sideBarTitle:String
    },
   
    watch:{
        searchTxt(newVal){
            this.$emit('input',newVal)
        }
    },
  components: {
   
  },
  computed: {
   
  },
  data() {
    return {
      searchTxt:'',
      showSidebar: false
    };
  },
  methods: {

  },
}
</script>
<style>
#filterSideBar{
  border-radius: 17px!important;
  background-color: #ffffff !important;
}
/* .b-sidebar-body{
  background: white!important;
} */
</style>
<style scoped>

.search-text:focus-visible{
  outline:none;
}
.search:focus-within svg {
  color: var(--primary-color);
}

.icon,.filter-icon{
color:rgb(227, 227, 227);
}
.search{
 border-radius: 6px;
border: 0.8px solid var(--border-color);
color:#667085;
}

 .search:focus-within{
 
    /* border-color: #4d1b7e; */
    outline: 1px solid var(--primary-color);

}
.cursor-pointer{
    cursor:pointer;
}

</style>

<template>
    <div
              class="card"
              style="
                background: #b3afb614;
                max-width: 500px;
                border-radius: 16px;
                border: 1px solid #ededed;
              "
            >
              <div class="card-body">
                <div
                  class="d-flex justify-content-center flex-column align-items-center"
                >
                <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="4" y="4" width="56" height="56" rx="28" fill="#EADDF6"/>
<rect x="4" y="4" width="56" height="56" rx="28" stroke="#F4EFF9" stroke-width="8"/>
<path d="M36 38L42 32L36 26M28 26L22 32L28 38" stroke="#4D1B7E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                </div>
                <div class="integration-heading mt-2">
                    <slot name="title">
                        Content not found
                    </slot>
                </div>
                <div class="integration-description my-2">
                    
                 <slot name="description">
                    Time to fill your shelves or refine your search. Remember, your results will appear right here.
                 </slot>
                </div>
                <div class="mt-4 d-flex justify-content-center" v-if="showButton">
                 <Button style="min-width:100%" :isLoading="isLoading" @click="$emit('click')">
                    {{ btnTitle }}
                 </Button>
                </div>
              </div>
            </div>
</template>
<script>
import Button from './Button.vue';
export default{
    props:{
        btnTitle:{
            type:String,
            default:'Create new'
        },
        showButton:Boolean,
        isLoading:Boolean
    },
    components:{
        Button
    }
}
</script>
<style>
.goToIntegrations,
.goToIntegrations:hover {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  background-color: #4d1b7e;
  color: #fff;
  padding: 10px 20px;
  border-radius: 6px;
  text-align: center;
}

.integration-heading {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: rgba(16, 24, 40, 1);
}
.integration-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: rgba(102, 112, 133, 1);
}</style>